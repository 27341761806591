<template>
  <div>
    <div class="setting-block-statistics">
      <div class="statistics-block">
        <div class="statistics-block-title">Кол-во пользователей</div>
        <div class="statistics-block-value">
          {{ users.length }}
        </div>
      </div>
      <div class="statistics-block">
        <div class="statistics-block-title">Кол-во групп</div>
        <div class="statistics-block-value">
          {{ groups.length }}
        </div>
      </div>
      <div class="statistics-block">
        <div class="statistics-block-title">Прошли курс</div>
        <div v-if="users" class="statistics-block-value">
          {{ endCourse(!group.isSmart ? users : groups) }} %
        </div>
      </div>
      <div class="statistics-block">
        <div class="statistics-block-title">Средний результат</div>
        <div class="statistics-block-value">
          {{ averageMeth(!group.isSmart ? users : groups) }} %
        </div>
      </div>
    </div>
    <div class="container-header-appointment">
      <div style="width: 300px">
        <SearchInput class="mr-4" v-model="search" label="Поиск" />
      </div>
      <!-- <div>
            <v-btn depressed class="button-white-black mr-4">
              Ошибки назначения
            </v-btn>
          </div> -->
      <div class="block-buttons">
        <v-btn @click="dialogRadio = true" v-if="!group.isSmart" class="button-white-blue" style="margin-right: 34px"
          depressed>
          <v-icon small>mdi-account-plus</v-icon>
          <span>Сделать доступным пользователю</span>
        </v-btn>
        <v-btn @click="dialogRadio = true" v-else class="button-white-blue" style="margin-right: 34px" depressed>
          <v-icon small>mdi-folder-plus</v-icon>
          <span>Сделать доступным группе</span> </v-btn>
        <SwitchButtons v-model="group.isSmart" :items="tabsItems" />
      </div>
    </div>
    <div class="container-table">
      <Table :headers="checkBoolSwitch(group.isSmart)" :items="!group.isSmart ? users : groups" headerFixed>
        <template v-slot:[`item.delete`]="{ item }">
          <v-icon @click="
            promiseConfirm(
              'Удаление назначения',
              `Вы уверены что хотите удалить назначение курса ${!group.isSmart ? 'пользователю' : 'группе'
              } «${!group.isSmart ? item.fullName : item.groupName}» ?`,
              item.id
            )
          ">mdi-close</v-icon>
        </template>

        <template v-slot:[`item.dateAssignment`]="{ item }">
          {{ item.dateAssignment | moment("DD.MM.YYYY") }}
        </template>
        <template v-slot:[`item.expired`]="{ item }">

          <div class="d-flex align-center">
            <div class="mr-2">
              {{ item.expired | moment("DD.MM.YYYY") }} 
            </div>
            <div v-if="item.isAutoReassignmentAvailable" >
              |  <v-icon small>mdi-replay</v-icon> {{ item.reassignmentDaysNumber ? item.reassignmentDaysNumber : 0 }}
            </div>
          </div>

        </template>
      </Table>
    </div>
    <Dialog v-model="dialogRadio" :titleText="
      !group.isSmart
        ? 'Назначение курса пользователям'
        : 'Назначение курса группе'
    " ref="dialogAppointment" :refForm="'formAppointment'" @click:success="appointmentCourse" :mainItems="[
  { slotName: 'radio' },
  {
    slotName: 'date',
  },
  { slotName: 'table' },
]">
      <template v-slot:date>
        <template v-if="radioValue == 0">
          <DateInput label="Дата" valid v-model="dialogItems.dateMs" :hideDetails="true" clearable>
          </DateInput>
        </template>
        <template v-if="radioValue == 1">
          <TextInput v-model="dialogItems.days" class="mb-4" label="Дней" valid type="number" :hideDetails="true"
            clearable>
          </TextInput>
          <SwitchInput v-model="dialogItems.isAutoReassignmentAvailable" icon="mdi-replay"
            label="Автоматическое повторное назначение" />
          <TextInput :disabled="!dialogItems.isAutoReassignmentAvailable" v-model="dialogItems.reassignmentDaysNumber"
            placeholder="Кол-во дней, через которое курс будет назчен повторно" type="number" :hideDetails="true"
            clearable></TextInput>
        </template>

      </template>

      <template v-slot:radio>
        <RadioButtons :items="radioArr" v-model="radioValue" />
      </template>

      <template v-slot:table>
        <div class="mt-8" style="height: 300px;">
          <Table :items="!group.isSmart ? usersAll : groupsAll"
            :headers="!group.isSmart ? dialogHeadersUsers : dialogHeadersGroups" headerHidden>
            <template v-slot:[`item.checkBox`]="{ item }">
              <div @click="selectItems(item)">
                <CheckBox v-model="item.checkBox" color="#0078FF" />
              </div>
            </template>

            <template v-slot:[`item.fullName`]="{ item }">
              <template v-if="item.fullName">
                {{ item.fullName }}
              </template>

              <template v-else-if="item.email">
                {{ item.email }}
              </template>

              <template v-else-if="item.phoneNumber">
                {{ item.phoneNumber }}
              </template>
            </template>

            dateAssignment
          </Table>
        </div>
      </template>

      <template v-slot:buttonDelete>
        Выбрано: {{ targetIds.length }}
      </template>
    </Dialog>
    <PromiseConfirm ref="promiseConfirm" />
  </div>
</template>

<script>
import Dialog from "../../components/main/dialog/Dialog.vue";
import PromiseConfirm from "../../components/main/dialog/promiseConfirm.vue";
import CheckBox from "../../components/main/inputs/CheckBox.vue";
import DateInput from "../../components/main/inputs/DateInput.vue";
import RadioButtons from "../../components/main/inputs/RadioButtons.vue";
import SearchInput from "../../components/main/inputs/SearchInput.vue";
import SwitchButtons from "../../components/main/SwitchButtons.vue";
import SwitchInput from "@/components/main/inputs/SwitchInput";
import Table from "../../components/main/table/Table.vue";
export default {
  components: {
    SearchInput,
    SwitchButtons,
    Table,
    Dialog,
    DateInput,
    RadioButtons,
    CheckBox,
    PromiseConfirm,
    SwitchInput
  },
  data: () => ({
    loading: false,
    users: [],
    usersAll: [],

    dialogItems: {
      days: null,
      dateMs: null,
      isAutoReassignmentAvailable: false,
      reassignmentDaysNumber: null
    },

    targetIds: [],
    dialogHeadersUsers: [{
      align: "flex-left",
      sortable: false,
      value: "checkBox",
      width: "fit-content",
    },
    {
      align: "flex-left",
      sortable: false,
      value: "fullName",
      width: "fit-content",
    },
    ],
    dialogHeadersGroups: [{
      align: "flex-left",
      sortable: false,
      value: "checkBox",
      width: "fit-content",
    },
    {
      align: "flex-left",
      sortable: false,
      value: "title",
      width: "fit-content",
    },
    ],
    selectedItemsUsers: [],
    radioArr: [
      { value: 0, name: "Пройти до определенной даты" },
      { value: 1, name: "Пройти за определеное количество дней" },
    ],
    radioValue: 0,
    search: "",
    dialogRadio: false,
    group: {
      title: "",
      isSmart: false,
    },
    tabsItems: [
      { text: "Пользователи", value: false },
      { text: "Группы", value: true },
    ],
    groups: [],
    groupsAll: [],
    headersGroups: [{
      text: "Дата назначения",
      value: "dateAssignment",
      align: "flex-left",
      width: "fit-content",
    },
    {
      text: "Название группы",
      value: "groupName",
      align: "flex-left",
      width: "fit-content",
    },
    {
      text: "Сотрудников в группе",
      value: "usersInGroup",
      align: "flex-left",
      width: "fit-content",
    },
    {
      text: "Ограничения",
      value: "expired",
      align: "flex-left",
      width: "fit-content",
    },
    {
      text: "Прохождение %",
      value: "courseProgress",
      align: "flex-left",
      width: "fit-content",
    },
    {
      text: "Результат %",
      value: "testResult",
      align: "flex-left",
      width: "fit-content",
    },
    {
      text: "",
      value: "delete",
      align: "flex-left",
      width: "fit-content",
    },
    ],
    headersUsers: [{
      text: "Дата назначения",
      value: "dateAssignment",
      align: "flex-left",
      width: "fit-content",
    },
    {
      text: "ФИО",
      value: "fullName",
      align: "flex-left",
      width: "fit-content",
    },
    {
      text: "Группа",
      value: "group",
      align: "flex-left",
      width: "fit-content",
    },
    {
      text: "Ограничения",
      value: "expired",
      align: "flex-left",
      width: "fit-content",
    },
    {
      text: "Прохождение %",
      value: "courseProgress",
      align: "flex-left",
      width: "fit-content",
    },
    {
      text: "Результат %",
      value: "testResult",
      align: "flex-left",
      width: "fit-content",
    },
    {
      text: "",
      value: "delete",
      align: "flex-left",
      width: "fit-content",
    },
    ],
  }),
  mounted() {
    this.getItems()
  },
  watch: {
    dialogRadio() {
      this.targetIds = [];
      if (!this.dialogRadio) {
        if (!this.group.isSmart) {
          for (let i = 0; i < this.users.length; i++) {
            this.users[i].checkBox = false;
          }
        } else {
          for (let i = 0; i < this.groups.length; i++) {
            this.groups[i].checkBox = false;
          }
        }
      }
    },
    search() {
      this.getUsersCourse()
    },
    'dialogItems.isAutoReassignmentAvailable'(){
      this.dialogItems.reassignmentDaysNumber = null
    }
  },
  methods: {
    async promiseConfirm(title, text, id) {
      if (await this.$refs.promiseConfirm.open(title, text)) {
        this.deleteAppointment(id);
      }
    },
    selectItems(item) {
      if (item.checkBox) {
        this.targetIds.push(item.id);
      } else {
        this.targetIds = this.targetIds.filter((v) => v != item.id);
      }
    },
    endCourse(arr) {
      if (arr.length != 0) {
        const arrElEndCourse = arr.filter((v) => v.courseProgress == 100);
        const percentOfUsersComplete =
          (arrElEndCourse.length * 100) / arr.length;
        return String(percentOfUsersComplete).slice(0, 5);
      } else {
        return 0;
      }
    },
    averageMeth(arr) {
      if (arr.length != 0) {
        const arrLength = arr.length;
        let sumArr = null;
        for (let i = 0; i < arrLength; i++) {
          sumArr = sumArr + arr[i].testResult;
        }
        return String(sumArr / arrLength).slice(0, 5);
      } else {
        return 0;
      }
    },
    checkBoolSwitch(isSmart) {
      if (isSmart) {
        return this.headersGroups;
      } else {
        return this.headersUsers;
      }
    },
    appointmentCourse(method) {
      this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/course/assignment", {
          id: this.$route.params.id,
          targetIds: this.targetIds,
          type: !this.group.isSmart ? "user" : "group",
          dateExpired: this.dialogItems.dateMs,
          days: parseInt(this.dialogItems.days),
          isAutoReassignmentAvailable: this.dialogItems.isAutoReassignmentAvailable ? this.dialogItems.isAutoReassignmentAvailable : false,
          reassignmentDaysNumber: parseInt(this.dialogItems.reassignmentDaysNumber),

        }, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        }
        )
        .then(() => {
          this.getItems()
          method();
          this.radioValue = 0
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    deleteAppointment(id) {
      this.$axios
        .delete(this.$store.getters.apiAdminPanelV4 + "/course/assignment", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.$route.params.id,
            targetId: id,
            type: !this.group.isSmart ? "user" : "group",
          },
        }).then(() => {
          this.getItems();
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    async getUsersCourse() {
      const arrConst = await this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/course/assignment/info", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.$route.params.id,
            search: this.search ? this.search : ''
          },
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
      this.users = arrConst.data.users;
      this.groups = arrConst.data.groups;
    },
    async getAllUsers() {
      await this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/user/list", {
          tag: '',
          search: '',
        }, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        }
        ).then((res) => {
          this.usersAll = res.data.map(element => {
            element.fullName =
              (element.lastName ? element.lastName + " " : "") +
              (element.firstName ? element.firstName + " " : "") +
              (element.middleName ? element.middleName : "");
            return element
          });
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    async getAllGroups() {
      await this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/group/list", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.groupsAll = res.data.map(i => {
            return i.group
          });
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    getItems() {
      Promise.all([this.getUsersCourse(), this.getAllUsers(), this.getAllGroups()]).then(() => {
        if (this.users.length > 0) {
          this.usersAll = this.usersAll.map(i => {
            if (!this.users.find(el => el.id === i.id)) {
              return i
            }
          }).filter(i => i)
        }
        if (this.groups.length > 0) {
          this.groupsAll = this.groupsAll.map(i => {
            if (!this.groups.find(el => el.id === i.id)) {
              return i
            }
          }).filter(i => i)
        }
      });



    }
  },
};
</script>

<style lang="scss" scoped>
.setting-block-statistics {
  display: flex;
  justify-content: space-between;
  padding: 32px 64px 0px 64px;

  .statistics-block {
    padding: 24px;
    background: var(--app-white);
    border-radius: 6px;
    margin: 0px 8px;
    width: 100%; // min-width: 385px;

    .statistics-block-title {
      font-size: 18px;
      font-weight: 600;
      color: var(--app-black);
      margin-bottom: 4px;
    }

    .statistics-block-value {
      font-size: 16px;
      color: var(--app-grey-3);
    }
  }

  .statistics-block:first-child {
    margin-left: 0px;
  }

  .statistics-block:last-child {
    margin-right: 0px;
  }
}

.block-items-setting {
  height: 100%;
  padding: 8px 64px;
  display: flex;
  justify-content: space-between;

  .setting-block {
    .setting-block-box {
      .block-box-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .box-title-text {
          font-size: 22px;
          font-weight: 600;
          color: var(--app-black);
        }
      }

      .block-box-items {
        .box-items-info {
          margin-bottom: 24px;

          .info-title {
            font-size: 12px;
            text-transform: uppercase;
            color: var(--app-grey-3);
          }

          .info-value {
            font-size: 14px;
            color: var(--app-black);
          }
        }
      }
    }
  }

  .setting-block:first-child {
    width: 60%;
    padding-right: 48px;
  }

  .setting-block:last-child {
    width: 40%;
    padding-left: 48px;
  }
}

.container-header-appointment {
  width: 100%;
  margin: 40px 0px;
  padding: 0 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  .block-buttons {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.container-table {
  padding: 0 64px;
  height: calc(100vh - 410px);
}
</style>