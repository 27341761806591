var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"test-lessons-block"},[_c('div',{staticClass:"lessons-block-title"},[_c('v-btn',{staticClass:"button-white-blue mr-4",attrs:{"depressed":""},on:{"click":function($event){return _vm.openDialog('video')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-youtube")]),_c('span',[_vm._v("Добавить видео")])],1),_c('v-btn',{staticClass:"button-white-blue mr-4",attrs:{"depressed":""},on:{"click":function($event){return _vm.openDialog('article')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-document")]),_c('span',[_vm._v("Добавить статью")])],1),_c('v-btn',{staticClass:"button-white-blue mr-4",attrs:{"depressed":""},on:{"click":function($event){return _vm.openDialog('cards')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-view-carousel")]),_c('span',[_vm._v("Добавить карточки")])],1),_c('v-btn',{staticClass:"button-white-blue",attrs:{"depressed":""},on:{"click":function($event){return _vm.openDialog('audio')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-music-note")]),_c('span',[_vm._v("Добавить аудио")])],1)],1),_c('div',{staticStyle:{"height":"calc(100vh - 285px)"}},[_c('Table',{attrs:{"items":_vm.items,"headers":_vm.headers,"headerFixed":"","draggable":""},on:{"click:row":_vm.clickItem,"click:draggable":_vm.newSort},scopedSlots:_vm._u([{key:"item.sort",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type == 'video')?_c('div',[_vm._v("Видео")]):(item.type == 'article')?_c('div',[_vm._v("Статья")]):(item.type == 'audio')?_c('div',[_vm._v("Аудио")]):(item.type == 'cards')?_c('div',[_vm._v("Карточки")]):_vm._e()]}},{key:"item.test",fn:function(ref){
var item = ref.item;
return [(item.test)?_c('v-icon',[_vm._v("mdi-check-circle-outline")]):_vm._e()]}}],null,true)})],1),_c('Dialog',{attrs:{"titleText":("Добавление урока - " + (_vm.lesson.type == 'video'
        ? 'видео'
        : _vm.lesson.type == 'cards'
        ? 'карточки'
        : _vm.lesson.type == 'article'
        ? 'статья'
        : _vm.lesson.type == 'audio'
        ? 'аудио'
        : '')),"refForm":'form',"mainItems":[
      {
        type: 'text',
        label: 'Название урока',
        value: this.lesson.name,
        vmodel: function (e) { return (this$1.lesson.name = e); },
        valid: true,
      },
      {
        type: 'image',
        label: 'Обложка',
        value: this.lesson.photo,
        vmodel: function (e) { return (this$1.lesson.photo = e); },
        valid: true,
      },
      {
        slotName: 'video',
      },
      {
        type: 'textarea',
        label: 'Описание',
        value: this.lesson.description,
        vmodel: function (e) { return (this$1.lesson.description = e); },
      },
      {
        type: 'switch',
        label: 'Видимость',
        icon: 'mdi-eye-outline',
        value: this.lesson.isVisible,
        vmodel: function (e) { return (this$1.lesson.isVisible = e); },
      } ],"buttonsFooter":{
      success: {
        title: 'Добавить урок',
        loading: true,
      },
    }},on:{"click:success":_vm.addLesson},scopedSlots:_vm._u([{key:"video",fn:function(){return [(_vm.lesson.type == 'video')?_c('div',[_c('VideoInput',{attrs:{"label":"Видео","valid":""},model:{value:(_vm.lesson.video),callback:function ($$v) {_vm.$set(_vm.lesson, "video", $$v)},expression:"lesson.video"}})],1):_vm._e(),(_vm.lesson.type == 'audio')?_c('div',[_c('FileInput',{attrs:{"label":"Файл аудио","acceptFiles":"audio/*"},model:{value:(_vm.lesson.audio),callback:function ($$v) {_vm.$set(_vm.lesson, "audio", $$v)},expression:"lesson.audio"}})],1):_vm._e()]},proxy:true}],null,false,1370896237),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }