<template>
  <div class="test-lessons-block" v-if="loading">
    <div class="lessons-block-title">
      <v-btn
        class="button-white-blue mr-4"
        depressed
        @click="openDialog('video')"
      >
        <v-icon small>mdi-youtube</v-icon>
        <span>Добавить видео</span>
      </v-btn>
      <v-btn
        class="button-white-blue mr-4"
        depressed
        @click="openDialog('article')"
      >
        <v-icon small>mdi-file-document</v-icon>
        <span>Добавить статью</span>
      </v-btn>
      <v-btn
        class="button-white-blue mr-4"
        depressed
        @click="openDialog('cards')"
      >
        <v-icon small>mdi-view-carousel</v-icon>
        <span>Добавить карточки</span>
      </v-btn>
      <v-btn class="button-white-blue" depressed @click="openDialog('audio')">
        <v-icon small>mdi-music-note</v-icon>
        <span>Добавить аудио</span>
      </v-btn>
    </div>
    <div style="height: calc(100vh - 285px)">
      <Table
        :items="items"
        :headers="headers"
        headerFixed
        @click:row="clickItem"
        draggable
        @click:draggable="newSort"
      >
        <template v-slot:[`item.sort`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <div v-if="item.type == 'video'">Видео</div>
          <div v-else-if="item.type == 'article'">Статья</div>
          <div v-else-if="item.type == 'audio'">Аудио</div>
          <div v-else-if="item.type == 'cards'">Карточки</div>
        </template>
        <template v-slot:[`item.test`]="{ item }">
          <v-icon v-if="item.test">mdi-check-circle-outline</v-icon>
        </template>
      </Table>
    </div>
    <Dialog
      v-model="dialog"
      :titleText="`Добавление урока - ${
        lesson.type == 'video'
          ? 'видео'
          : lesson.type == 'cards'
          ? 'карточки'
          : lesson.type == 'article'
          ? 'статья'
          : lesson.type == 'audio'
          ? 'аудио'
          : ''
      }`"
      :refForm="'form'"
      :mainItems="[
        {
          type: 'text',
          label: 'Название урока',
          value: this.lesson.name,
          vmodel: (e) => (this.lesson.name = e),
          valid: true,
        },
        {
          type: 'image',
          label: 'Обложка',
          value: this.lesson.photo,
          vmodel: (e) => (this.lesson.photo = e),
          valid: true,
        },
        {
          slotName: 'video',
        },
        {
          type: 'textarea',
          label: 'Описание',
          value: this.lesson.description,
          vmodel: (e) => (this.lesson.description = e),
        },
        {
          type: 'switch',
          label: 'Видимость',
          icon: 'mdi-eye-outline',
          value: this.lesson.isVisible,
          vmodel: (e) => (this.lesson.isVisible = e),
        },
      ]"
      @click:success="addLesson"
      :buttonsFooter="{
        success: {
          title: 'Добавить урок',
          loading: true,
        },
      }"
    >
      <template v-slot:video>
        <div v-if="lesson.type == 'video'">
          <VideoInput v-model="lesson.video" label="Видео" valid />
        </div>
        <div v-if="lesson.type == 'audio'">
          <FileInput
            label="Файл аудио"
            acceptFiles="audio/*"
            v-model="lesson.audio"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>
<script>
import VideoInput from "@/components/main/inputs/VideoInput";
import FileInput from "@/components/main/inputs/FileInput";

// import CheckBox from "@/components/main/inputs/CheckBox";
export default {
  components: {
    VideoInput,
    FileInput,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    loading: false,
    items: [],
    infoPhoto: "",
    dialog: false,
    textInput: "",
    lesson: {
      type: "",
      isVisible: false,
    },

    headers: [
      {
        text: "№",
        value: "sort",
      },
      {
        text: "Тип",
        value: "type",
      },
      {
        text: "Название",
        value: "name",
      },
      {
        text: "Тестирование",
        value: "test",
      },
      {
        text: "Прохождение %",
        value: "totallessonPercent",
      },
      {
        text: "Скачивания",
        value: "downloads",
      },
    ],
  }),
  methods: {
    clickItem(item) {
      this.$router.push({
        name: "Lesson",
        params: { id: item.id },
      });
    },
    openDialog(type) {
      this.lesson.type = type;
      this.dialog = true;
    },
    addLesson(method) {
      this.getInformationImage(this.lesson.photo)
        .then((result) => {
          let formData = new FormData();
          formData.append("courseId", this.item.id);
          formData.append("type", this.lesson.type);
          formData.append("name", this.lesson.name);
          formData.append("description", this.lesson.description || "");
          formData.append("photo", this.lesson.photo);
          formData.append("video", this.lesson.video || "");
          formData.append("audio", this.lesson.audio || "");
          formData.append("key", this.lesson.key || "");
          formData.append("isVisible", this.lesson.isVisible);
          formData.append("infoPhoto", JSON.stringify(result));
          formData.append("infoVideo", JSON.stringify({}));
          this.$axios
            .post(this.$store.getters.apiAdminPanelV4 + "/lesson", formData, {
              headers: {
                authorization: this.$store.getters.adminPanelHeaderAuth,
              },
            })
            .then(() => {
              this.getItems();
              method();
            })
            .catch((err) => {
              this.$store.commit("errorSet", true);
              this.$store.commit("errorTextSet", err);
            });
        })
        .catch(() => {
          this.$store.commit("errorSet", true);
          this.$store.commit(
            "errorTextSet",
            "Ошибка при получении информации картинки"
          );
          method();
        });
    },
    newSort(value, item) {
      this.$axios
        .put(
          this.$store.getters.apiAdminPanelV4 + "/lesson/sort",
          {
            id: item.id,
            sort: value,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {})
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    getItems() {
      this.$axios
        .get(
          this.$store.getters.apiAdminPanelV4 + "/lesson/list",

          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
            params: {
              id: this.item.id,
            },
          }
        )
        .then((res) => {
          this.items = res.data;
          this.loading = true;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
  },
  created() {
    this.getItems();
  },
  computed: {},
  mounted() {},

  watch: {},
};
</script>
<style lang="scss" scoped>
.test-lessons-block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--app-grey-1);
  padding: 32px 64px;
  .lessons-block-title {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }
}

.dialog-items-answers {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: #767676;
}
.dialog-items-answers-objects {
  .answers-objects-item {
    display: flex;
    .objects-item-checkbox {
      margin-top: 14px;
      margin-right: 16px;
    }
  }
  .objects-item-delete {
    padding-top: 10px;
    margin-left: 10px;
    cursor: pointer;
  }
}
.fade-enter-active {
  animation: bounce-in 0.5s;
}
.fade-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
