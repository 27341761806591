<template>
  <div class="modal-vue">
    <div class="overlay" v-if="dialog" @click="dialog = false"></div>
    <transition name="fade">
      <div class="modal" v-if="dialog">
        <div class="header-modal">
          <div>{{ title }}</div>
          <div>
            <v-icon @click="dialog = false">mdi-close</v-icon>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="body">
          <div>
            {{ message }}
          </div>
          <div class="footer-modal">
            <div class="buttons-footer">
              <div>
                <v-btn
                  v-if="!options.noconfirm"
                  depressed
                  class="button-white-blue"
                  @click.native="cancel"
                  >Отменить</v-btn
                >
              </div>

              <div>
                <v-btn
                  depressed
                  class="button-white-red"
                  @click.native="agree"
                  >Удалить</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: "#0033AB",
      zIndex: 1001,
      noconfirm: false,
    },
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-modal {
  padding: 40px 40px 20px 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  div:nth-child(1) {
    color: rgba(33, 28, 31, 1);
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
  }
}
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  .footer-modal {
    width: 100%;
    padding-top: 30px;
    .buttons-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
  transform: translateY(50px);
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modal-vue .overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: RGBA(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
}

.modal-vue .modal {
  position: fixed;
  z-index: 13;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("/gradient_dialog.png") no-repeat;
  background-color: #f7f7fa;
  border-radius: 2px;
  width: 600px;
  height: fit-content;
  border-radius: 10px;
  z-index: 1001;
}

.modal-vue .close {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>

